import dynamic from "next/dynamic";
import type { HomePageFooterWrapperProps } from "./Homepage";

export const Homepage = dynamic(() =>
    import("./Homepage").then((module) => module.Homepage),
);

export const HomePageFooterWrapper = dynamic<HomePageFooterWrapperProps>(() =>
    import("./Homepage").then((module) => module.HomePageFooterWrapper),
);
